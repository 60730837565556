.card-container {
    position: relative;
    width: 300px;
    height: 600px;
    margin: 20px auto;
  }
  
  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .flip-card-front {
    background-color: #fff;
    color: #000;
  }
  
  .flip-card-back {
    background-color: #f8f9fa;
    color: #000;
    transform: rotateY(180deg);
  }
  
  .card-content {
    padding-top: 10px;
  }
  
  .image-section {
    margin-top: 10px;
  }
  
  .card-image {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Position Checkmark and X Buttons */
  .card-actions {
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  
  .reject-button, .accept-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    background-color: red;
    padding: 5px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
  }
  
  .accept-button {
    background-color: green;
    right: 10px; /* Positioned in the top-right corner */
  }
  
  .reject-button {
    left: 10px; /* Positioned in the top-left corner */
  }
  
  .reject-button:hover, .accept-button:hover {
    opacity: 0.8;
  }
  
  /* Flip Button */
  .flip-button {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .flip-button:hover {
    background-color: #0056b3;
  }
  

  

  
  .card-wrapper {
    position: relative;
    width: 300px; /* Match the size of the card */
    height: 600px; /* Match the size of the card */
    margin: auto;
    background: #fff; /* Background to match the card */
    border-radius: 10px; /* Rounded corners to match the card */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for consistency */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures the image doesn’t overflow */
}

.tmp-word {
  align-items: top !important;
  height: 0px !important;
  margin: 0px !important;
  justify-content: top !important;
  background: none;
  border: none;
  padding: 5px;
  position: absolute;
  top: 14px;
}

.card-wrapper img {
    width: 100%; /* Make the image scale to the width of the card */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the card dimensions nicely */
    border-radius: 10px; /* Matches the card’s rounded corners */
}

  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light gray background */
    border-top: 4px solid #3498db; /* Blue spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Spinning animation */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  