/* CardNumberInput.css */
.numeric-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style the input field */
  .numeric-input-field {
    text-align: center;
    font-size: 14px;
    width: 80px;
    padding: 5px;
  }
  
  /* Position the increment (up) button on the right */
  .numeric-input-btn-up {
    order: 2;              /* ensure it comes after the input */
    margin-left: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
  }
  
  /* Use a right arrow for the up button */
  .numeric-input-btn-up:before {
    content: "→";
  }
  
  /* Position the decrement (down) button on the left */
  .numeric-input-btn-down {
    order: 0;              /* ensure it comes before the input */
    margin-right: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
  }
  
  /* Use a left arrow for the down button */
  .numeric-input-btn-down:before {
    content: "←";
  }
  