body {
    background: #f8f9fa;
    font-family: 'Calibri', sans-serif !important;
}

.ellipsis-2-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    overflow: hidden;
}

.ellipsis-3-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    overflow: hidden;
}

.black {
    font-family: 'Helvetica', 'Arial', sans-serif; /* Clean sans-serif font */
    font-weight: 700; /* Bold */
    color: #000; /* Black */
    margin: 0;
    padding: 0;
}

.h2-size {
    font-size: 52px;
}

.p-size {
    font-size: 21px;
}

.red {
    font-family: 'Futura', 'Proxima Nova', 'Montserrat', sans-serif; /* Contemporary sans-serif font */
    font-weight: 600; /* Semi-bold */
    font-size: 36px; /* Adjust based on your layout */
    color: #d14366; /* Soft pinkish-red */
    margin: 0;
    padding: 0;
}

.grey {
    font-family: 'Futura', 'Proxima Nova', 'Montserrat', sans-serif; /* Contemporary sans-serif font */
    font-weight: 600; /* Semi-bold */
    font-size: 36px; /* Adjust based on your layout */
    color: grey; 
    margin: 0;
    padding: 0;
}

.dark-button {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    font-family: 'Arial', sans-serif; /* Use a clean sans-serif font */
    font-weight: 600; /* Semi-bold */
    font-size: 16px; /* Adjust font size */
    padding: 12px 24px; /* Padding for a balanced look */
    border: none; /* Remove border */
    border-radius: 24px; /* Rounded edges */
    text-align: center; /* Center the text */
    cursor: pointer; /* Pointer cursor on hover */
    display: inline-block; /* Ensure button-like behavior */
    transition: all 0.3s ease; /* Smooth hover effect */
}

.dark-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
}

.light-button {
    background-color: #f8f9fa; /* Light gray background */
    color: rgb(61, 58, 58); /* Black text */
    font-family: 'Arial', sans-serif; /* Use a clean sans-serif font */
    font-weight: 600; /* Semi-bold */
    font-size: 16px; /* Adjust font size */
    padding: 12px 24px; /* Padding for a balanced look */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 24px; /* Rounded edges */
    text-align: center; /* Center the text */
    cursor: pointer; /* Pointer cursor on hover */
    display: inline-block; /* Ensure button-like behavior */
    transition: all 0.3s ease; /* Smooth hover effect */
}

.light-button:hover {
    background-color: #e6e6e6; /* Slightly darker gray on hover */
}

.link {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits the color from the parent element */
    font-weight: 600; /* Optional: Make the text semi-bold */
}
