.main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 45rem;
  }
  
  @media (max-width: 768px) {
    .main-div {
      padding-left: 2rem; /* Remove the extra left padding on mobile */
    }
  }
  